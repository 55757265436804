import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

export const addCustomer = createAsyncThunk('customers/addCustomer', async (customerDetails, thunkAPI) => {

	const res = await axios(`${process.env.REACT_APP_API_URL}/customer`, {
		method: 'POST',
		headers: {
			'Content-Type': 'application/json'
		},
		withCredentials: true,
		data: customerDetails
	});

	return res.data;
})

export const getCustomersCount = createAsyncThunk('invoices/getCustomersCount', async () => {

	const res = await axios(`${process.env.REACT_APP_API_URL}/customer-count`, {
		withCredentials: true,
	});

	return res.data;
})

export const deleteCustomer = createAsyncThunk('customers/deleteCustomer', async (id, thunkAPI) => {

	const res = await axios(`${process.env.REACT_APP_API_URL}/customer/${id}`, {
		withCredentials: true,
		method: 'DELETE'
	});

	return res.data;
})

export const getCustomers = createAsyncThunk('customers/getCustomers', async () => {

	const res = await axios(`${process.env.REACT_APP_API_URL}/customers/`, {
		withCredentials: true,
	});

	return res.data;
})

export const filterCustomers = createAsyncThunk('customers/filterCustomers', async (filterTxt, thunkAPI) => {

	const res = await axios(`${process.env.REACT_APP_API_URL}/customers/search/${filterTxt}`, {
		withCredentials: true,
	});

	return res.data;
})

export const updateCustomer = createAsyncThunk('customers/updateCustomer', async (customerDetails, thunkAPI) => {

	const res = await axios(`${process.env.REACT_APP_API_URL}/customer`, {
		method: 'PATCH',
		headers: {
			'Content-Type': 'application/json'
		},
		withCredentials: true,
		data: customerDetails
	});

	return res.data;
})

export const viewCustomer = createAsyncThunk('customers/viewCustomer', async (id, thunkAPI) => {

	const res = await axios(`${process.env.REACT_APP_API_URL}/customer/${id}`, {
		withCredentials: true,
	});

	return res.data;
})