import { createSlice } from "@reduxjs/toolkit"
import { toast } from "react-toastify";
import { getServices, filterServices, deleteService, addService, updateService } from '../thunk/thunk-services'

const servicesSlice = createSlice({
	name: 'services',
	initialState: {
		loading: false,
		entities: [],
		error: null,
		count: 0,
		status: {}
	},
	reducers: {},
	extraReducers: {
		/**
		 * After adding service to DB showing it the end user
		 */
		[addService.pending]: (state, action) => {
			state.loading = true
			state.status = {}
		},
		[addService.rejected]: (state, action) => {
			state.error = action.payload
			state.status = {}
		},
		[addService.fulfilled]: (state, action) => {
			state.loading = false
			state.entities = [...state.entities, action.payload]
			toast('👍 Service added', {
				toastId: action.payload.id
			})
		},
		/**
		 * Getting all services from the DB
		 */
		[getServices.pending]: (state, action) => {
			state.loading = true
			state.status = {}
		},
		[getServices.rejected]: (state, action) => {
			state.error = action.payload
			state.entities = []
			state.status = {}
		},
		[getServices.fulfilled]: (state, action) => {
			state.loading = false
			state.entities = action.payload.items
			state.count = action.payload.count
		},
		/**
		 * updating service to DB showing it the end user
		 */
		[updateService.pending]: (state, action) => {
			state.loading = true
			state.status = {}
		},
		[updateService.rejected]: (state, action) => {
			state.error = action.payload
			state.status = {}
		},
		[updateService.fulfilled]: (state, action) => {
			state.loading = false
			const serviceIndex = state.entities.findIndex(item => item.id === action.payload.id)
			state.entities[serviceIndex].serviceName = action.payload.serviceName
			toast('👍 Service updated', {
				toastId: action.payload.id
			})
		},
		/**
		 * Deleting the service from DB and removing the visibility for the end user
		 */
		[deleteService.pending]: (state, action) => {
			state.loading = true
			state.status = {}
		},
		[deleteService.rejected]: (state, action) => {
			state.error = action.payload
			state.status = {}
		},
		[deleteService.fulfilled]: (state, action) => {
			state.loading = false
			state.entities = state.entities.filter(item => item.id !== action.payload.id)
			toast('👍 Service deleted', {
				toastId: action.payload.id
			})
		},
		/**
		 * Filter services from db
		 */
		[filterServices.pending]: (state, action) => {
			state.loading = true
			state.status = {}
		},
		[filterServices.rejected]: (state, action) => {
			state.loading = false
			state.error = action.payload
			state.entities = []
			state.status = {}
		},
		[filterServices.fulfilled]: (state, action) => {
			state.loading = false
			state.entities = action.payload.items
			state.count = action.payload.count
			// state.entities = action.payload.items
		}
	}
})

export default servicesSlice.reducer