import React, { Suspense, lazy } from 'react';
import AppLoader from './AppLoader'

const AppRouter = lazy(_ => import('./AppRouter'))

const Billing = () => {

	return (
		<Suspense fallback={<AppLoader />}>
			<AppRouter />
		</Suspense>
	)
}

export default Billing;