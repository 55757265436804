import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

export const addInvoice = createAsyncThunk('invoices/addInvoice', async (invoiceDetails, thunkAPI) => {
	// return console.log(invoiceDetails);
	const res = await axios(`${process.env.REACT_APP_API_URL}/invoice`, {
		method: 'POST',
		headers: {
			'Content-Type': 'application/json'
		},
		withCredentials: true,
		data: invoiceDetails
	});

	return res.data;
})

export const getInvoicesCount = createAsyncThunk('invoices/getInvoicesCount', async () => {

	const res = await axios(`${process.env.REACT_APP_API_URL}/invoice-count`, {
		withCredentials: true,
	});

	return res.data;
})

export const deleteInvoice = createAsyncThunk('invoices/deleteInvoice', async (param, thunkAPI) => {

	const res = await axios(`${process.env.REACT_APP_API_URL}/invoice/${param.id}`, {
		withCredentials: true,
		method: 'DELETE'
	});

	return res.data;
})

export const getInvoices = createAsyncThunk('invoices/getInvoices', async () => {

	const res = await axios(`${process.env.REACT_APP_API_URL}/invoices`, {
		withCredentials: true,
	});

	return res.data;
})

export const filterInvoices = createAsyncThunk('invoices/filterInvoices', async (filterTxt) => {

	const res = await axios(`${process.env.REACT_APP_API_URL}/invoices/search/${filterTxt}`, {
		withCredentials: true,
	});

	return res.data;
})

export const updateInvoice = createAsyncThunk('invoices/updateInvoice', async (invoiceDetails, thunkAPI) => {
	// console.log(invoiceDetails);
	const res = await axios(`${process.env.REACT_APP_API_URL}/invoice`, {
		method: 'PATCH',
		headers: {
			'Content-Type': 'application/json'
		},
		withCredentials: true,
		data: invoiceDetails
	});

	return res.data;
})

export const viewInvoice = createAsyncThunk('invoices/viewInvoice', async (params, thunkAPI) => {

	const res = await axios(`${process.env.REACT_APP_API_URL}/invoice/${params.id}`, {
		withCredentials: true,
	});

	return res.data;
})