import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

export const getServices = createAsyncThunk('services/getServices', async () => {

	const res = await axios(`${process.env.REACT_APP_API_URL}/services`, {
		withCredentials: true
	})

	return res.data;
})

export const addService = createAsyncThunk('services/addService', async (serviceDetails, thunkAPI) => {

	const res = await axios(`${process.env.REACT_APP_API_URL}/service`, {
		method: 'POST',
		headers: {
			'Content-Type': 'application/json'
		},
		withCredentials: true,
		data: serviceDetails
	})

	return res.data;
})

export const deleteService = createAsyncThunk('services/deleteService', async ({ id, serviceName }, thunkAPI) => {

	const res = await axios(`${process.env.REACT_APP_API_URL}/service/${id}/${serviceName}`, {
		withCredentials: true,
		method: 'DELETE'
	});

	return res.data;
})

export const filterServices = createAsyncThunk('services/filterServices', async (filterTxt) => {

	const res = await axios(`${process.env.REACT_APP_API_URL}/services/${filterTxt}`, {
		withCredentials: true,
	});

	return res.data;
})

export const updateService = createAsyncThunk('services/updateService', async (serviceDetails, thunkAPI) => {

	const res = await axios(`${process.env.REACT_APP_API_URL}/service`, {
		method: 'PATCH',
		headers: {
			'Content-Type': 'application/json'
		},
		withCredentials: true,
		data: serviceDetails
	});

	return res.data;
})