import { createSlice } from "@reduxjs/toolkit"
import { toast } from "react-toastify";
import { getCustomers, filterCustomers, deleteCustomer, addCustomer, updateCustomer, viewCustomer, getCustomersCount } from '../thunk/thunk-customers'

const initialState = {
	loading: false,
	entities: [],
	entity: {},
	error: null,
	count: 0,
	queriedCount: 0,
	domainRows: []
}

const customersSlice = createSlice({
	name: 'customers',
	initialState,
	reducers: {
		clearCustomerData: _ => initialState,
		setDomainRow: (state, action) => {
			const objID = state.domainRows.findIndex(e => e.id === action.payload.id)
			if (objID >= 0) {
				state.domainRows[objID] = { ...state.domainRows[objID], ...action.payload }
			} else {
				state.domainRows = [...state.domainRows, action.payload]
			}
		},
		rmDomainRow: (state, action) => {
			state.domainRows = state.domainRows.filter(e => e.id !== action.payload)
		}
	},
	extraReducers: {
		/**
		 * After adding customer to DB showing it the end user
		 */
		[getCustomersCount.rejected]: (state, action) => {
			state.error = action.payload
		},
		[getCustomersCount.fulfilled]: (state, action) => {
			let { count, scannedCount } = action.payload.count
			state.loading = false
			state.count = count
			state.queriedCount = scannedCount
		},
		/**
		 * After adding customer to DB showing it the end user
		 */
		[addCustomer.pending]: (state, action) => {
			state.loading = true
		},
		[addCustomer.rejected]: (state, action) => {
			state.error = action.payload
		},
		[addCustomer.fulfilled]: (state, action) => {
			state.loading = false
			// state.entities = state.entities.concat(action.payload)
			state.queriedCount++
			toast('👍 Customer added', {
				toastId: action.payload.id
			})
		},
		/**
		 * After adding customer to DB showing it the end user
		 */
		[viewCustomer.pending]: (state, action) => {
			state.loading = true
		},
		[viewCustomer.rejected]: (state, action) => {
			state.error = action.payload
		},
		[viewCustomer.fulfilled]: (state, action) => {
			state.loading = false
			state.entity = action.payload.customerDetails
			state.domainRows = state.entity.domains || []
		},
		/**
		 * Getting all customers from the DB
		 */
		[getCustomers.pending]: (state, action) => {
			state.loading = true
		},
		[getCustomers.rejected]: (state, action) => {
			state.error = action.payload
			state.entities = []
		},
		[getCustomers.fulfilled]: (state, action) => {
			state.loading = false
			state.entities = action.payload.items
			state.count = action.payload.count
			state.queriedCount = action.payload.queriedCount ? action.payload.queriedCount : state.count
		},
		/**
		 * updating customer to DB showing it the end user
		 */
		[updateCustomer.rejected]: (state, action) => {
			state.error = action.payload
		},
		[updateCustomer.fulfilled]: (state, action) => {
			state.loading = false
			state.entity = action.payload.customerDetails
			toast(`👍 Customer details updated`, {
				toastId: action.payload.id
			})
		},
		/**
		 * Deleting the customer from DB and removing the visibility for the end user
		 */
		[deleteCustomer.rejected]: (state, action) => {
			state.error = action.payload
		},
		[deleteCustomer.fulfilled]: (state, action) => {
			state.loading = false
			state.entities = state.entities.filter(item => item.id !== action.payload.id)
			state.queriedCount > 0 && state.queriedCount--
			state.count > 0 && state.count--
			toast(`👍 Customer deleted`, {
				toastId: action.payload.id
			})
		},
		/**
		 * Filter customers from db
		 */
		[filterCustomers.rejected]: (state, action) => {
			state.loading = false
			state.error = action.payload
			state.entities = []
		},
		[filterCustomers.fulfilled]: (state, action) => {
			state.loading = false
			state.entities = action.payload.items
			state.queriedCount = action.payload.count
		}
	}
})

export const { clearCustomerData, setDomainRow, rmDomainRow } = customersSlice.actions
export default customersSlice.reducer