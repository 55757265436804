import { createSlice } from "@reduxjs/toolkit"
import { toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import { addInvoice, getInvoices, filterInvoices, deleteInvoice, updateInvoice, viewInvoice, getInvoicesCount } from '../thunk/thunk-invoices'
import { viewCustomer } from '../thunk/thunk-customers'

const initialState = {
	loading: false,
	entities: [],
	entity: {},
	error: null,
	count: 0,
	queriedCount: 0,
	customerSearchResult: [],
	customerDetails: {},
	selectedCustomer: null,
	particulars: [],
	domain: '',
	payment_mode: '',
	cheque_no: '',
	cheque_bank_name: '',
	cheque_branch: '',
	cheque_date: '',
	payment_type: 'full',
	tax_base: 'none',
	discount: 0,
	sub_total: 0,
	tax_amt: 0,
	partial_advance: [],
	total: 0,
	status: '',
	financialYear: undefined,
	invNo: 1214,
	monthCount: 0,
	monthIncome: 0,
	yearCount: 0,
	yearIncome: 0,
	overAllIncome: 0
}

const invoicesSlice = createSlice({
	name: 'invoices',
	initialState,
	reducers: {
		setInvNo(state, action) {
			state.invNo = action.payload
		},
		setCustomerSearchResult(state, action) {
			if (action.payload) {
				state.customerSearchResult = action.payload
			}
		},
		selectedCustomer(state, action) {
			state.selectedCustomer = action.payload
		},
		setParticular(state, action) {
			const objID = state.particulars.findIndex(particular => particular.id === action.payload.id)
			if (objID >= 0) {
				state.particulars[objID] = Object.assign(state.particulars[objID], action.payload)
			} else {
				state.particulars = [...state.particulars, action.payload]
			}
		},
		rmParticular: (state, action) => {
			state.particulars = state.particulars.filter(e => e.id !== action.payload)
		},
		emptyParticular(state, action) {
			state.particulars = []
		},
		setCost(state, action) {
			const objID = state.particulars.findIndex(particular => particular.id === action.payload.id)

			if (objID >= 0) {
				state.particulars[objID] = Object.assign(state.particulars[objID], action.payload)
			} else {
				state.particulars = [...state.particulars, action.payload]
			}
			const sub_totalValues = state.particulars.map(p => (p.cost === undefined || p.cost === '') ? 0 : parseInt(p.cost))
			state.sub_total = sub_totalValues.reduce((total, num) => total + num)
			state.tax_amt = state.tax_base === 'gst' ? (state.sub_total * 18 / 100) : 0
			state.total = state.sub_total + state.tax_amt - state.discount
		},
		rmCost(state) {
			const sub_totalValues = state.particulars.map(p => (p.cost === undefined || p.cost === '') ? 0 : parseInt(p.cost))
			state.sub_total = sub_totalValues.reduce((total, num) => total + num)
			state.tax_amt = state.tax_base === 'gst' ? (state.sub_total * 18 / 100) : 0
			state.total = state.sub_total + state.tax_amt - state.discount
		},
		setDiscount(state, action) {
			state.discount = action.payload
			state.tax_amt = state.tax_base === 'gst' ? (state.sub_total * 18 / 100) : 0
			state.total = state.sub_total + state.tax_amt - state.discount
		},
		setDomain(state, action) {
			state.domain = action.payload
		},
		setPaymentMode(state, action) {
			state.payment_mode = action.payload
		},
		setChequeNo(state, action) {
			state.cheque_no = action.payload
		},
		setChequeBankName(state, action) {
			state.cheque_bank_name = action.payload
		},
		setChequeDate(state, action) {
			state.cheque_date = action.payload
		},
		setChequeBranch(state, action) {
			state.cheque_branch = action.payload
		},
		setPaymentType(state, action) {
			state.payment_type = action.payload
		},
		setPartialAdvance(state, action) {
			const objID = state.partial_advance.findIndex(advance => advance.id === action.payload.id)

			if (objID >= 0) {
				state.partial_advance[objID] = Object.assign(state.partial_advance[objID], action.payload)
			} else {
				state.partial_advance = [...state.partial_advance, action.payload]
			}
		},
		setSubtotal(state, action) {
			state.sub_total = action.payload
		},
		setTotal(state, action) {
			state.total = action.payload
		},
		setTaxBase(state, action) {
			state.tax_base = action.payload
			state.tax_amt = state.tax_base === 'gst' ? (state.sub_total * 18 / 100) : 0
			state.total = state.sub_total + state.tax_amt - state.discount
		},
		setStatus(state, action) {
			state.status = action.payload
		},
		clearInvoiceData: _ => initialState
	},
	extraReducers: {
		/**
		 * After adding invoice to DB showing it the end user
		 */
		[getInvoicesCount.pending]: (state, action) => {
			state.loading = true
		},
		[getInvoicesCount.rejected]: (state, action) => {
			state.error = action.payload
		},
		[getInvoicesCount.fulfilled]: (state, action) => {
			state.loading = false
			state.Count = action.payload.count
		},
		/**
		 * After adding invoice to DB showing it the end user
		 */
		[addInvoice.pending]: (state, action) => {
			state.loading = true
		},
		[addInvoice.rejected]: (state, action) => {
			state.error = action.payload
		},
		[addInvoice.fulfilled]: (state, action) => {
			state.loading = false
			// state.entities = state.entities.concat(action.payload)
			state.queriedCount++
			toast('👍 Invoice added', {
				toastId: action.payload.id
			})
			state.customerSearchResult = []
		},
		/**
		 * After adding invoice to DB showing it the end user
		 */
		[viewInvoice.pending]: (state, action) => {
			state.loading = true
		},
		[viewInvoice.rejected]: (state, action) => {
			state.error = action.payload
		},
		[viewInvoice.fulfilled]: (state, action) => {
			state.loading = false
			state.entity = action.payload
			state.invNo = action.payload.invNo
			state.discount = action.payload.invoiceDetails.discount
			state.domain = action.payload.invoiceDetails.domain
			state.partial_advance = action.payload.invoiceDetails.partial_advance
			state.particulars = action.payload.invoiceDetails.particulars
			state.payment_mode = action.payload.invoiceDetails.payment_mode
			state.cheque_no = action.payload.invoiceDetails.cheque_no
			state.cheque_bank_name = action.payload.invoiceDetails.cheque_bank_name
			state.cheque_branch = action.payload.invoiceDetails.cheque_branch
			state.cheque_date = action.payload.invoiceDetails.cheque_date
			state.payment_type = action.payload.invoiceDetails.payment_type
			state.status = action.payload.invoiceDetails.status
			state.sub_total = action.payload.invoiceDetails.sub_total
			state.tax_amt = action.payload.invoiceDetails.tax_amt
			state.tax_base = action.payload.invoiceDetails.tax_base
			state.total = action.payload.invoiceDetails.total
			state.financialYear = action.payload.financialYear
		},
		/**
		 * Getting all invoices from the DB
		 */
		[getInvoices.pending]: (state, action) => {
			state.loading = true
		},
		[getInvoices.rejected]: (state, action) => {
			state.error = action.payload
			state.entities = []
		},
		[getInvoices.fulfilled]: (state, action) => {
			state.loading = false
			state.entities = action.payload.items
			state.count = action.payload.count
			state.queriedCount = action.payload.queriedCount ? action.payload.queriedCount : state.count

			// below codes are for dashboard summary
			let thisYear = new Date().getFullYear(),
				thisMonth = new Date().getMonth(),
				thisMonthStart = new Date().setFullYear(thisYear, thisMonth, 1),
				thisYearStart = new Date().setFullYear(thisYear, 0, 1)

			const lifeTimeIncome = action.payload.items.map(e => e.invoiceDetails.total)
			// console.log(lifeTimeIncome.reduce((a, b) => a + b, 0));
			state.overAllIncome = lifeTimeIncome.reduce((a, b) => a + b, 0)

			const monthlyIncome = action.payload.items.map(e => new Date(e.timestamp) >= thisMonthStart && new Date(e.timestamp) <= Date.now() && e.invoiceDetails.total)
			state.monthIncome = monthlyIncome.reduce((a, b) => a + b, 0)
			state.monthCount = monthlyIncome.length

			const yearlyIncome = action.payload.items.map(e => new Date(e.timestamp) >= thisYearStart && new Date(e.timestamp) <= Date.now() && e.invoiceDetails.total)
			state.yearIncome = yearlyIncome.reduce((a, b) => a + b, 0)
			state.yearCount = yearlyIncome.length

		},
		/**
		 * updating invoice to DB showing it the end user
		 */
		[updateInvoice.rejected]: (state, action) => {
			state.error = action.payload
		},
		[updateInvoice.fulfilled]: (state, action) => {
			state.loading = false
			state.entity = action.payload
			toast(`👍 Invoice details updated`, {
				toastId: action.payload.id
			})
		},
		/**
		 * Deleting the invoice from DB and removing the visibility for the end user
		 */
		[deleteInvoice.rejected]: (state, action) => {
			state.error = action.payload
		},
		[deleteInvoice.fulfilled]: (state, action) => {
			state.loading = false
			state.entities = state.entities.filter(item => item.id !== action.payload.id)
			state.queriedCount > 0 && state.queriedCount--
			state.Count > 0 && state.Count--
			toast(`👍 Invoice deleted`, {
				toastId: action.payload.id
			})
			// console.log(state.Count, state.queriedCount);
		},
		/**
		 * Filter invoices from db
		 */
		[filterInvoices.rejected]: (state, action) => {
			state.loading = false
			state.error = action.payload
			state.entities = []
		},
		[filterInvoices.fulfilled]: (state, action) => {
			console.log(action.payload.count);
			state.loading = false
			state.entities = action.payload.items
			state.queriedCount = action.payload.count
		},
		/**
		 * Filter customer from db
		 */
		[viewCustomer.rejected]: (state, action) => {
			state.loading = false
			state.error = action.payload
		},
		[viewCustomer.fulfilled]: (state, action) => {
			state.loading = false
			let data = action.payload
			state.customerDetails = { ...state.customerDetails, [data.id]: data }
		}
	}
})

export const {
	setInvNo,
	selectedCustomer,
	setCustomerSearchResult,
	setParticular,
	rmParticular,
	emptyParticular,
	setCost,
	rmCost,
	setDiscount,
	setDomain,
	setPaymentMode,
	setPaymentType,
	setChequeDate,
	setChequeBankName,
	setChequeNo,
	setChequeBranch,
	setTaxBase,
	setPartialAdvance,
	setSubtotal,
	setTotal,
	setStatus,
	clearInvoiceData
} = invoicesSlice.actions

export default invoicesSlice.reducer