import React from 'react';
import { Provider } from 'react-redux';
import store from "./redux/Store";
import axios from 'axios'

import { ToastContainer } from 'react-toastify';
import Billing from './components/Billing';
import './scss/ansi.scss';

import 'bootstrap';

const App = () => {

	axios.defaults.withCredentials = true;

	axios.interceptors.response.use(response => {
		return response;
	}, error => {
		const { status } = error.response

		if (status === 401) {
			window.alert('Your session expired so please login again');
		} else {
			window.alert('Something went wrong please try again after sometime!');
		}
		return error;
	});

	return (
		<Provider store={store}>
			<Billing />
			<ToastContainer pauseOnFocusLoss={false} autoClose={3000} bodyClassName="text-dark" />
		</Provider>
	)
};

export default App;
